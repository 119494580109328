export const addPartnersBadgeScript = () => {
	const body = document.body;
	const scriptTag = document.createElement('script');
	const scriptSrc = `https://apis.google.com/js/platform.js`;

	scriptTag.src = scriptSrc;
	scriptTag.async = true;
	scriptTag.defer = true;

	body.appendChild(scriptTag);
};
