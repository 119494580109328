import React from 'react';
import './src/style/fonts.css';
import './src/style/reset.css';

import { addPartnersBadgeScript } from './src/util/addPartnersBadgeScript';

import { ParallaxProvider } from 'react-scroll-parallax';

export const wrapRootElement = ({ element }) => {
	return <ParallaxProvider>{element}</ParallaxProvider>;
};

export const onRouteUpdate = () => {
	addPartnersBadgeScript();
};
